var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.css()},[(_vm.isLoading)?_c('AtomLoading',{attrs:{"floating":""}}):_vm._e(),_c('div',{class:_vm.css('content'),attrs:{"else":""}},[_c('MolBackNav',{attrs:{"route":{ name: 'payment-links.index', params: { businessId: _vm.businessId } },"label":_vm.t('module.payment_link.back_label'),"force-route":""}}),_c('OrgHeaderInfo',{class:_vm.css('header'),attrs:{"heading":_vm.t('module.payment_link.create.page_heading'),"sub-heading":_vm.t('module.payment_link.create.page_subheading')},scopedSlots:_vm._u([{key:"links",fn:function(){return [_c('MolGuideLink',{attrs:{"article":_vm.MolGuideLinkArticleEnum.LEARN_MORE_PAYMENT_LINKS}}),_c('MolGuideLink',{attrs:{"article":_vm.MolGuideLinkArticleEnum.TERMS_AND_CONDITIONS},nativeOn:{"click":function($event){return _vm.eventTrack(_vm.EventEnum.PAYMENT_LINK_TERMS_AND_CONDITIONS)}}})]},proxy:true}])}),_c('div',{class:_vm.css('amount')},[_c('AtomText',{class:_vm.css('label'),attrs:{"type":_vm.AtomTextTypeEnum.BODY_BOLD}},[_vm._v(" "+_vm._s(_vm.t("module.payment_link.amount"))+" ")]),_c('OrgFormMoneyInput',{class:_vm.css('form'),attrs:{"data-test":"amount","value":_vm.amount,"locale":_vm.locale,"currency-code":_vm.currencyCode,"on-change":function (value) { return (_vm.amount = value ? value : 0); }}})],1),_c('div',{class:_vm.css('number-of-uses')},[_c('AtomText',{class:_vm.css('label'),attrs:{"type":_vm.AtomTextTypeEnum.BODY_BOLD}},[_vm._v(" "+_vm._s(_vm.t("module.payment_link.create.number_of_uses"))+" ")]),_c('OrgFormInput',{class:_vm.css('form'),attrs:{"data-test":"number-of-uses","type":"number","min":_vm.minNumberOfUses,"max":_vm.maxNumberOfUses,"step":1,"value":_vm.numberOfUses,"error-msg":_vm.numberOfUsesError,"success-msg":_vm.tc(
            'module.payment_link.create.number_of_uses_help_text',
            _vm.numberOfUses,
            {
              count: _vm.numberOfUses,
            }
          ),"on-change":function (value) { return (_vm.numberOfUses = Number(value)); }}})],1),(_vm.outletList.length)?_c('div',{class:_vm.css('outlet')},[_c('AtomText',{class:_vm.css('label'),attrs:{"type":_vm.AtomTextTypeEnum.BODY_BOLD}},[_vm._v(" "+_vm._s(_vm.t("module.payment_link.outlet"))+" ")]),_c('MolMultiselect',{class:_vm.css('form'),attrs:{"allow-empty":"","list":_vm.outletList,"on-input":function (value) { return (_vm.outletId = value ? value.id : ''); },"data-test":"outlet-select","placeholder":_vm.t('module.payment_link.select_an_outlet')}})],1):_vm._e(),_c('div',{class:_vm.css('purpose')},[_c('AtomText',{class:_vm.css('label'),attrs:{"type":_vm.AtomTextTypeEnum.BODY_BOLD}},[_vm._v(" "+_vm._s(_vm.t("module.payment_link.purpose_payment"))+" ")]),_c('OrgFormInput',{attrs:{"data-test":"purpose","value":_vm.purpose,"maxlength":"191","placeholder":_vm.t('module.payment_link.purpose_payment_placeholder'),"on-change":function (value) { return (_vm.purpose = value); }}})],1),_c('div',{class:_vm.css('message')},[_c('div',{class:_vm.css('label')},[_c('AtomText',{attrs:{"type":_vm.AtomTextTypeEnum.BODY_BOLD}},[_vm._v(" "+_vm._s(_vm.t("module.payment_link.message"))+" ")]),_c('AtomText',{attrs:{"type":_vm.AtomTextTypeEnum.SMALL,"color":_vm.AtomTextColorEnum.STORMY}},[_vm._v(" "+_vm._s(_vm.t("module.payment_link.create.optional"))+" ")])],1),_c('div',{class:_vm.css('form')},[_c('AtomAutoSizeTextarea',{attrs:{"value":_vm.message,"maxlength":_vm.maxLengthMessage,"on-input":function (value) { return (_vm.message = value); },"rows":2,"placeholder":_vm.t('module.payment_link.message_placeholder')}}),_c('AtomText',{class:_vm.css('counter'),attrs:{"type":_vm.AtomTextTypeEnum.SMALL,"color":_vm.message.length < _vm.maxLengthMessage
              ? _vm.AtomTextColorEnum.STORMY
              : _vm.AtomTextColorEnum.CORAL}},[_vm._v(" "+_vm._s(_vm.messageCounter)+" ")])],1)]),_c('div',{class:_vm.css('cta')},[_c('AtomButton',{attrs:{"data-test":"create-btn","size":_vm.AtomButtonSizeEnum.LARGE_WIDE,"is-loading":_vm.isCreating,"on-click":function () { return _vm.create(); },"is-disabled":_vm.isDisabled}},[_vm._v(" "+_vm._s(_vm.t("module.payment_link.create_payment_link"))+" ")])],1)],1),_c('div',{class:_vm.css('preview')},[_c('div',{class:_vm.css('preview-header')},[_c('AtomText',{attrs:{"type":_vm.AtomTextTypeEnum.H2}},[_vm._v(" "+_vm._s(_vm.t("module.payment_link.preview"))+" ")])],1),_c('div',{class:_vm.css('preview-content')},[_c('PaymentLinkCardPreview',{class:_vm.css('preview-card'),attrs:{"business-name":_vm.businessName,"business-logo":_vm.businessLogo,"payment-value":_vm.amountFormatted,"purpose-payment":_vm.purpose,"message":_vm.message}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }